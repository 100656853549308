import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { country } from "@prisma/client";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import LayoutHome from "../src/Layout/components/LayoutHome";
import prisma from "../src/Prisma/lib/getClient";

import Slider from "../src/Slider/components";
import perks from "../src/Slider/data/dataSlider";

const defaultPage = "/bundle";

Index.getLayout = function getLayout(page) {
  return <LayoutHome>{page}</LayoutHome>;
};

export default function Index({ countries }: { countries: country[] }) {
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const router = useRouter();

  function shop() {
    router.push(`${selectedCountry.code.toLowerCase()}${defaultPage}`);
  }

  return (
    <main className="overflow-hidden">
      <div className="h-[80vh] w-[100vw] bg-transparent ">
        <div className="absolute inset-0 w-full h-full overflow-hidden bg-center bg-no-repeat bg-cover bg-gradient-to-b from-gray-700 via-gray-600 sm:bg-gradient-to-b sm:from-gray-500 sm:via-gray-500 sm:to-gray-50 ">
          <Image
            src="https://static.shopatsns.com/assets/images/Hero-section.svg"
            className="h-full mix-blend-overlay"
            layout="fill"
            objectFit="cover"
          ></Image>
        </div>
        <div className="absolute inset-0 flex flex-col justify-center h-full mx-auto">
          <div className="self-center">
            <p className="ml-auto mr-auto text-5xl font-bold text-center text-white sm:text-7xl font-primary mb-14 ">
              The Diaspora Connect Platform
            </p>
          </div>
          <div className="flex flex-row p-1 pb-4 mx-auto my-6 space-x-2 sm:p-4">
            <div className="flex flex-col justify-center">
              <p className="pr-4 text-xs sm:text-lg m text-background">Which country are you shopping for</p>
            </div>
            <div>
              <Listbox value={selectedCountry} onChange={setSelectedCountry}>
                {({ open }) => (
                  <>
                    {/* <Listbox.Label className="font-medium text-gray-700">
                      Which country are you shopping for?
                    </Listbox.Label> */}
                    <div className="relative">
                      <Listbox.Button className="flex flex-row justify-between w-full p-2 rounded  border border-gray-10 shadow-md cursor-default text-background w-[100px] sm:w-[140px] bg-white bg-opacity-10 backdrop-blur-sm font-primary sm:min-w-[130px]">
                        <div>{selectedCountry.name}</div>
                        <div>
                          <ChevronDownIcon className="w-5 h-5 font-semibold text-white-400" />
                        </div>
                      </Listbox.Button>
                      <Transition
                        show={open}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg"
                      >
                        <Listbox.Options className="py-1 overflow-auto leading-6 max-h-60">
                          {countries.map((country, index) => (
                            <Listbox.Option key={index} value={country} disabled={false}>
                              {({ active }) => (
                                <div
                                  className={`${
                                    active ? "text-white bg-blue-600" : "text-gray-900"
                                  } cursor-default select-none relative p-2`}
                                >
                                  <span>{country.name}</span>
                                </div>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          <div className="self-center bg-gray-500 border rounded bg-opacity-60 backdrop-blur-sm hover:cursor-pointer border-white-30">
            <button
              type="button"
              className="p-4 text-lg font-primary  text-white  w-[338px] sm:w-[380px] md:w-[460px]"
              onClick={shop}
            >
              SHOP NOW
            </button>
          </div>
        </div>
      </div>

      {/* Hero section */}
      <div className="relative h-5/6">
        {/* Background image and overlap */}
        <div aria-hidden="true" className="absolute inset-0 hidden sm:flex sm:flex-col">
          <div className="relative flex-1 w-full bg-gray-800">
            <div className="absolute inset-0 bg-gray-900 opacity-50" />
          </div>
          <div className="w-full h-32 bg-white md:h-40 lg:h-48" />
        </div>
      </div>

      <section
        aria-labelledby="perks-heading"
        className="  bg-[url('https://static.shopatsns.com/assets/images/middle-gradient.svg')] mt-10 bg-cover"
      >
        <h2 id="perks-heading" className="sr-only">
          Our perks
        </h2>
        {/* <Slider  slides={images}
        size="medium"
        slideInterval={6}
        inContainer
        className="mt-20 mb-20"/> */}

        <div className="flex flex-col items-center justify-center py-24 mx-auto text-center max-w-7xl sm:py-32 lg:px-8">
          <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-3  lg:gap-y-0 justify-items-center rounded-lg bg-white w-[1080px] hidden sm:grid">
            {perks.map((perk) => (
              <div
                key={perk.description}
                className="hidden pl-20 pr-32 mt-20 mb-20 text-center md:flex md:items-center md:text-left lg:block lg:text-center sm:block lg:border-r-2 lg:last:border-none"
              >
                <div className="md:flex-shrink-0 ">
                  <div className="flow-root ">
                    <img className="w-auto mx-auto h-54 " src={perk.imageUrl} alt="" />
                  </div>
                </div>
                <div className="mt-10 md:mt-0 md:ml-4 lg:mt-14 lg:ml-0">
                  <p className="mt-3 text-sm text-xl font-normal text-gray-500 text-indigo-900 font-primary">
                    {perk.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="sm:hidden">
          <Slider />
        </div>
      </section>
    </main>
  );
}

export async function getStaticProps() {
  const countries = await prisma.country.findMany({
    where: {
      vendor: {
        some: {},
      },
    },
  });
  return {
    props: {
      countries: JSON.parse(JSON.stringify(countries)),
    },
    revalidate: 60 * 15,
  };
}
