const dataSlider = [
  {
    imageUrl: "/assets/images/icons/perks/easy to use.svg",
    description: "Easy to use platform",
  },
  {
    imageUrl: "/assets/images/icons/perks/Affordable.svg",
    description: "Affordable Products",
  },
  {
    imageUrl: "/assets/images/icons/perks/1Mdreams.svg",
    description:
      "1 Million Dreams",
  },
];

export default dataSlider;