import React, { useState, useEffect } from "react";
import dataSlider from "../data/dataSlider";

export default function Slider() {
  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(dataSlider.length);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  return (
    <div className="max-w-2xl h-[280px]   relative flex flex-col border-black">
      {dataSlider.map((obj, index) => {
        return (
          <div
            key={obj.description}
            className={
              slideIndex === index + 1
                ? "w-full h-full absolute flex justify-center items-center  opacity-1  mb-40 -mt-32 "
                : "w-full h-full absolute opacity-0 -mt-32 "
            }
          >
            <div className=" text-center flex flex-col items-center  justify-center  bg-white rounded-lg shadow-lg md:w-full w-[400px] h-[340px]">
              <img src={obj.imageUrl} />
              <p className="mt-[75px] font-primary font-normal text-xl text-indigo-900">{obj.description}</p>
            </div>
          </div>
        );
      })}

      <div className="flex items-center justify-center  my-48 inset-x-1/2 z-10">
        {Array.from({ length: 3 }).map((item, index) => (
          <div
            key={index}
            onClick={() => moveDot(index + 1)}
            className={
              slideIndex === index + 1
                ? "w-[30px] h-[12px] rounded-full border-gray border-solid   bg-blue-400 my-0 "
                : " w-[15px] h-[15px] rounded-full  border-gray border-solid my-0 mx-2 bg-white "
            }
          ></div>
        ))}
      </div>
    </div>
  );
}
