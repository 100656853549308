import Footer from "../Footer/components";
import Link from "next/link";
import Image from "next/image";

export default function LayoutHome({ children }) {
  return (
    <>
      <header className="relative h-[70px] sm:h-[137px]  z-10 ">
        <nav className="flex h-full mr-5 sm:-mt-8">
          <div className="h-[40px] w-[134.85px] sm:h-[137px] sm:w-[203px] flex items-center justify-center">
            <div className="w-[134.85px] h-[40px] sm:w-[202.27px] sm:h-[60px] relative hover:cursor-pointer mt-14 ml-4 ">
              <Link href={"/"} passHref>
                <a>
                  <Image
                    src="https://static.shopatsns.com/assets/images/icons/logo/ShopAtSNS.png"
                    className=""
                    layout="fill"
                    objectFit="contain"
                  ></Image>
                </a>
              </Link>
            </div>
          </div>
        </nav>
      </header>
      {children}
      <Footer />
    </>
  );
}
