import { useSession } from "next-auth/react";
import React from "react";
import Link from 'next/link';

const footerNavigation = {
  snsInternational: [
    { name: "Liberia", href: "tel:+2310880332000", contact:'+231(0)880332000'},
    { name: "WhatsApp", href: "tel:+2310880332000", contact:'+231(0)880332000' },
    { name: "Email", href: "mailto:dushan@snsafrica.co", contact:'dushan@snsafrica.co' },
  ],
  aboutus: [
    { name: "About SNS", href: "https://www.snsafrica.co" },
    { name: "Return Policy", href: { pathname: "/legalities/return-policy", query: { countryCode: 'lbr' } } },
    { name: "Privacy Policy", href: { pathname: "/legalities/privacy-policy", query: { countryCode: 'lbr' } } },
    { name: "Terms and Conditions", href: { pathname: "/legalities/terms-and-conditions", query: { countryCode: 'lbr' } } },
  ],
};


export default function Footer() {
  const { data: session } = useSession();

  const userName = session?.user?.name.split(" ")[0] || "Guest";

  return (
    <footer aria-labelledby="footer-heading mb-0" className="relative bg-white">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 mx-auto max-w-7xl">
        <div className="">
          <div className="flex justify-between pt-6 mb-6 font-bold font-primary">
            {/* logo section */}
            <div className="relative sm:w-[202.27px]">
              <img
                src="https://static.shopatsns.com/assets/images/icons/logo/ShopAtSNS.png"
                alt=""
                className="self-start h-10 relative pl-0 w-29 sm:h-[60px] sm:w-[202.27px] absolute -left-3 sm:-left-4"
              />

              <p className="text-xs font-semibold mt-7 sm:text-lg">{`Hi, ${userName}


              `}</p>
            </div>

            <div>
              <p className="font-semibold md:text-4xl mt-6 ml-6 font-black text-gray-900 justify-center text-indigo-900 underline decoration-indingo-700 bg-gradient-to-r from-blue-400 to-green-600">
                <a href="https://www.youtube.com/watch?v=XnuRyUBQbGw">Click here to Watch our Story</a>
              </p>
            </div>

            {/* social media section */}
            <div className="grow-0">
              <p className="flex flex-col items-end justify-center mt-2 sm:mt-6 sm:mb-6">JOIN US</p>

              <ul role="list" className="flex items-center justify-between mt-9">
                <li className="mr-5">
                  <a href="https://m.facebook.com/shopatsns/photos/?tab=album&album_id=102032778451394" target={'_blank'} className="text-gray-500 hover:text-gray-600">
                    <img
                      src="https://static.shopatsns.com/assets/images/icons/social/facebook.svg"
                      alt=""
                      className="h-5"
                    />
                  </a>
                </li>
                <li className="mr-5">
                  <a href="https://instagram.com/shop_at_sns?igshid=YmMyMTA2M2Y=" target={'_blank'} className="text-gray-500 hover:text-gray-600">
                    <img
                      src="https://static.shopatsns.com/assets/images/icons/social/instagram.svg"
                      alt=""
                      className="w-auto h-5"
                    />
                  </a>
                </li>
                <li className="">
                  <a href="" className="text-gray-500 hover:text-gray-600">
                    <img
                      src="https://static.shopatsns.com/assets/images/icons/social/twitter.svg"
                      alt=""
                      className="w-auto h-5"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex flex-wrap items-baseline justify-between grid-cols-5 mb-10 border-t-2 sm:grid border-backgroundLight font-primary">
            {/* SNS INTERNATIONAL */}
            <div className="mt-6 grow-1">
              <h3 className="text-lg font-medium font-bold text-dark ">SNS INTERNATIONAL</h3>
              <ul role="list" className="mt-6 space-y-3">
                {footerNavigation.snsInternational.map((item) => (
                  <li key={item.name} className="text-sm ">
                    <a href={item.href} className="flex justify-between">
                      {item.name} <span className="text-blue-600 text-xs"> {item.contact}</span>
                    </a>

                  </li>
                ))}
              </ul>
            </div>

            {/* Newsletter section */}
            <div className="order-first bottom basis-full sm:order-none sm:col-span-3 grow sm:pl-12">
              <div className="grow-2 shrink-0">
                <p className="text-xs font-normal mt-9 mb-9 sm:text-lg ">
                  Subscribe to our newsletter to get the latest update
                  <br />
                  on offers and discounts
                </p>
                <form className="flex mt-10 mb-1 ">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>

                  <input
                    id="email-address"
                    type="text"
                    autoComplete="email"
                    required
                    placeholder="Email"
                    className="relative font-primary h-16 grow-2  flex-4 min-w-100% w-full sm:w-[500px] bg-backgroundLight border border-white   pl-16 text-base  placeholder-emailbox  "
                  />
                  <div className="absolute items-center self-center justify-center ml-6 p-17">
                    <img
                      src="https://static.shopatsns.com\assets\images\icons\social\email.svg"
                      alt=""
                      className="w-5 w-auto h-5"
                    />
                  </div>

                  {/* hidden */}
                  <div className="flex-shrink-0 ml-1 sm:block">
                    <button
                      type="submit"
                      className="text-lg font-primary sm:w-[130px] w-full bg-dark border border-transparent   h-16 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* ABOUT US  */}

            <div className="mt-6 ">
              <h3 className="text-lg font-bold text-right xs:text-left ">ABOUT US</h3>
              <ul role="list" className="mt-6 space-y-3 ">
                {footerNavigation.aboutus.map((item) => (
                  <li key={item.name} className="mb-3 text-sm font-normal text-right">
                    <Link href={item.href} passHref>
                      <a href="" className="">
                        {item.name}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
